<template>
    <div class="contact-us-all">
        <!-- <div class="common-title contact-title">Contact Us</div> -->
		<!-- <img v-if="$device.mobile" src="@/assets/images/contactUs/contactMobile.png" alt="" class="contact-img"> -->
		<!-- <img v-else src="@/assets/images/contactUs/contactPC.jpg" alt="" class="contact-img"> -->
		<img src="@/assets/images/contactUs/3nAddress.jpg" alt="3nAddress" class="contact-img">

    </div>
</template>
<script>
	import '@/assets/less/ContactUs.less';
	export default {
		name: 'ContactUs',
		components: {},
		data() {
			return {}
		},
	}
</script>